import React, { useEffect, useState } from "react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { useStaticQuery, graphql } from "gatsby";
import { MDXProvider } from "@mdx-js/react";

//Shared Componenets
import Header from "../components/shared/header/Header";
import Footer from "../components/shared/footer/Footer";

// Cloudinary
import { AdvancedImage, responsive } from "@cloudinary/react";
import { Cloudinary } from "@cloudinary/url-gen";

// Macro for styling mdx privder passed down to children
import tw from "twin.macro";

// STYES
const H1 = tw.h1`
  text-gray-600 py-2 text-5xl font-bold
`;
const H2 = tw.h2`
  text-gray-400 py-2 text-3xl font-bold
`;
const H3 = tw.h3`
  text-mustard py-2 text-xl font-bold
`;
const STRONG = tw.strong`
  text-gray-600 font-bold
`;
const ITALICS = tw.em`
  text-gray-600
`;
const BLOCK_QUOTE = tw.blockquote`
  bg-gray-300 rounded-lg rounded-br-lg p-5 my-2 shadow-lg
`;
const ORDERED_LIST = tw.ol`
  list-decimal
`;
const UNORDERED_LIST = tw.ul`
  list-disc
`;
const HORIZONTAL_RULER = tw.hr`
  bg-gray-300 h-[2px] my-12
`;
const LINK = tw.a`
  underline text-mustard
`;
const IMAGE = tw.img`
  rounded-lg my-12 shadow-lg w-full
`;
const TABLE = tw.table`
min-w-full divide-y divide-gray-300 my-5 shadow-lg border-4
`;
const TABLE_HEAD = tw.thead`
bg-gray-50
`;
const TABLE_H = tw.th`
px-3 py-3.5 text-left text-sm font-semibold text-gray-900
`;
const TABLE_BODY = tw.tbody`
divide-y divide-gray-200 bg-white
`;
const TABLE_D = tw.td`
whitespace-nowrap px-3 py-4 text-sm text-gray-500
`;
const PRE = tw.pre`
bg-gradient-to-r from-indigo-100 via-purple-100 to-pink-100 p-5 rounded-lg shadow-lg my-5
`;


function TestimonialsTemplate(props) {
  // Here pageContext is passed in at build time by gatsby-node.js
  const { image_url, title, mdx, description } = props.pageContext;
  const [myMdx, setMyMdx] = useState(null);

  // Create a Cloudinary instance and set your cloud name.
  const cld = new Cloudinary({
    cloud: {
      cloudName: "kirkwork",
    },
  });
  // define our cloudinary images
  const ResuableImage = (image_url) => cld.image(image_url).quality("auto");

  const totalMdx = useStaticQuery(graphql`
    query MyQuery {
      allMdx {
        edges {
          node {
            internal {
              content
            }
            body
          }
        }
      }
    }
  `);

  // console.log(JSON.stringify(totalMdx.allMdx.edges))

  useEffect(() => {
    async function getMdx() {
      // This query will get all of your posts

      const foundEdge = await totalMdx.allMdx.edges.find((edge) => {
        return edge.node.internal.content === mdx;
      });

      console.log(JSON.stringify(foundEdge.node.body));

      setMyMdx(foundEdge.node.body);
    }

    getMdx();
  }, [mdx, totalMdx.allMdx.edges]);

  return (
    <div id="root">
      <Header />

      <div className="relative min-h-screen bg-gray-200">
        <div className="absolute top-0 w-full h-64 bg-slate-600" />

        <div className="flex pt-24 px-4">
          <div className="flex-col max-w-3xl mx-auto">
            {/** Header to our post */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              {/* Image */}
              <div className="relative group block w-full aspect-w-1 aspect-h-1 overflow-hidden">
                <AdvancedImage
                  plugins={[responsive({ steps: 200 })]}
                  cldImg={ResuableImage(image_url)}
                  alt="Surfer"
                  className="flex-1 object-cover"
                />
              </div>
              {/* Title/ Description */}
              <div className="flex items-end">
                <div className="relative">
                  <h1 className=" text-left text-4xl font-extrabold tracking-tight">
                    <span className="block text-slate-600 uppercase">
                      {title}
                    </span>
                  </h1>
                  <p className={"text-mustard font-bold text-md max-w-md"}>
                    {description}
                  </p>
                </div>
              </div>
            </div>

            {/** Post Body */}
            <div className="py-12">
              {/* Cant get markdown to work! */}
              {myMdx && (
                <MDXProvider
                  components={{
                    h1: H1,
                    h2: H2,
                    h3: H3,
                    strong: STRONG,
                    blockquote: BLOCK_QUOTE,
                    em: ITALICS,
                    ol: ORDERED_LIST,
                    ul: UNORDERED_LIST,
                    hr: HORIZONTAL_RULER,
                    a: LINK,
                    img: IMAGE,
                    table: TABLE,
                    thead: TABLE_HEAD,
                    th: TABLE_H,
                    tbody: TABLE_BODY,
                    td: TABLE_D,
                    pre: PRE,
                  }}
                >
                  <MDXRenderer>{myMdx}</MDXRenderer>
                </MDXProvider>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default TestimonialsTemplate;
